.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
}

.experience__details {
  display: flex;
  gap: 1rem;
}

.experience__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

.experience__details small {
  font-size: 1.1rem; /* Increased size */
  color: #ccc; /* Lighter text color, adjust as needed */
  font-weight: 500; /* Making text slightly bolder */
  line-height: 1.5; /* Improved line spacing */
}

.header__socials a {
  font-size: 32px; /* Adjust icon initial size as needed */
  color: #C0E0FF   ; /* Initial color, change as needed */
  transition: transform 0.3s ease-in-out, color 0.3s ease;
}

.header__socials a:hover {
  transform: scale(1.1); /* Slight enlargement */
  color: var(--color-primary); /* Change color on hover */
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  .experience__container {
      grid-template-columns: 1fr;
  }

  .experience__container > div {
      width: 80%;
      padding: 2rem;
      margin: 0 auto;
  }

  .experience__content {
      padding: 1rem;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================= */
/* Media Queries for Mobile Devices */
@media screen and (max-width: 600px) {
  .header__socials a {
    font-size: 24px; /* Smaller icon size for mobile */
  }

  .experience__container {
    gap: 1rem;
  }

  .experience__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }

  /* Hide social icons on very small devices if necessary */
  .header__socials {
    display: flex; /* Ensuring flex layout persists */
  }
}